import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { server } from "src/coreConfig";
import { setTaxesElement } from "src/slices/taxes";
import { useDispatch, useSelector } from "src/store";
import { handleDownloadFile } from "src/utils/dataRenders";
import useAsyncRequest from "./useAsyncRequest";

const urls = {
  taxes: {
    config: `${server}/api/v2/taxes/config/`,
    activity: {
      classifier: `${server}/api/v2/taxes/activity/classifier/`,
      incomes: `${server}/api/v2/taxes/activity/incomes/`,
      index: `${server}/api/v2/taxes/activity/`,
    },
    declaration: {
      attachVat: `${server}/api/v2/taxes/declaration/attachVat/`,
      createAdmin: `${server}/api/v2/taxes/declaration/createAdmin/`,
      createDefinitive: `${server}/api/v2/taxes/declaration/createDefinitive/`,
      getFileTypes: `${server}/api/v2/taxes/declaration/getFileTypes/`,
      addFile: `${server}/api/v2/taxes/declaration/addFile/`,
      update: `${server}/api/v2/taxes/declaration/update/`,
      declaration: `${server}/api/v2/taxes/declaration/`,
      delete: `${server}/api/v2/taxes/declaration/delete/`,
      deleteFee: `${server}/api/v2/taxes/declaration/deleteFee/`,
      deleteFile: `${server}/api/v2/taxes/declaration/deleteFile/`,
      deleteVat: `${server}/api/v2/taxes/declaration/deleteVat/`,
      detail: `${server}/api/v2/taxes/declaration/detail/`,
      downloadVat: `${server}/api/v2/taxes/declaration/downloadVat/`,
      downloadFile: `${server}/api/v2/taxes/declaration/downloadFile/`,
      income: `${server}/api/v2/taxes/declaration/income/`,
      list: `${server}/api/v2/taxes/declaration/list/`,
      state: `${server}/api/v2/taxes/declaration/state/`,
    },
    download_report: `${server}/api/v2/taxes/report/licenseXls/`,
    generateReport: `${server}/api/v2/taxes/report/generate/`,
    deleteReport: `${server}/api/v2/taxes/report/delete/`,
    downloadReport: `${server}/api/v2/taxes/report/download/`,
    reportList: `${server}/api/v2/taxes/report/list/`,
    handle: `${server}/api/v2/taxes/handle/`,
    last_period: `${server}/api/v2/taxes/period/last/`,
    license: {
      add: `${server}/api/v2/taxes/license/add/`,
      addActivity: `${server}/api/v2/taxes/license/addActivity/`,
      addNote: `${server}/api/v2/taxes/license/addNote/`,
      addNoteFile: `${server}/api/v2/taxes/license/addNoteFile/`,
      assign: `${server}/api/v2/taxes/license/assign/`,
      change: `${server}/api/v2/taxes/license/change/`,
      changeActivity: `${server}/api/v2/taxes/license/changeActivity/`,
      changeNote: `${server}/api/v2/taxes/license/changeNote/`,
      createFromImage: `${server}/api/v2/taxes/license/createFromImage/`,
      notes: `${server}/api/v2/taxes/license/notes/`,
      detail: `${server}/api/v2/taxes/license/detail/`,
      downloadCertificate: `${server}/api/v2/cert/certificate/license/`,
      downloadList: `${server}/api/v2/taxes/license/downloadList/`,
      declarations: `${server}/api/v2/taxes/license/declarations/`,
      downloadNoteFile: `${server}/api/v2/taxes/license/downloadNoteFile/`,
      downloadPending: `${server}/api/v2/taxes/license/download/pending/`,
      feeRequest: `${server}/api/v2/taxes/license/feeRequest/`,
      feeRequestDelete: `${server}/api/v2/taxes/license/feeRequest/delete/`,
      feeRevision: `${server}/api/v2/taxes/license/feeRevision/`,
      feeRevisionDelete: `${server}/api/v2/taxes/license/feeRevision/delete/`,
      list: `${server}/api/v2/taxes/license/list/`,
      pendingPeriods: `${server}/api/v2/taxes/license/pendingPeriods/`,
      pendingDefinitive: `${server}/api/v2/taxes/license/pendingDefinitive/`,
      resetDeclarations: `${server}/api/v2/taxes/license/resetDeclarations/`,
      revision: `${server}/api/v2/taxes/license/revision/`,
      search: `${server}/api/v2/taxes/license/search/`,
      sync: `${server}/api/v2/taxes/license/sync/`,
      transfer: `${server}/api/v2/taxes/license/transfer/`,
      transferToEmail: `${server}/api/v2/taxes/license/transferToEmail/`,
      exempt: `${server}/api/v2/taxes/license/exempt/`,
      unassign: `${server}/api/v2/taxes/license/unassign/`,
      getProcedures: `${server}/api/v2/taxes/license/getProcedures/`,
      legal: {
        detail: `${server}/api/v2/taxes/license/legal/detail/`,
      },
      closed: {
        detail: `${server}/api/v2/taxes/license/closed/detail/`,
      },
    },
    penalty: {
      add: `${server}/api/v2/taxes/penalty/add/`,
      attach: `${server}/api/v2/taxes/penalty/attach/`,
      change: `${server}/api/v2/taxes/penalty/change/`,
      delete: `${server}/api/v2/taxes/penalty/delete/`,
      detail: `${server}/api/v2/taxes/penalty/detail/`,
      downloadAttach: `${server}/api/v2/taxes/penalty/downloadAttach/`,
      list: `${server}/api/v2/taxes/penalty/list/`,
    },
    period: {
      activities: `${server}/api/v2/taxes/period/activities/`,
      addFee: `${server}/api/v2/taxes/period/addFee/`,
      declarations: `${server}/api/v2/taxes/period/declarations/`,
      deleteFee: `${server}/api/v2/taxes/period/deleteFee/`,
      main: `${server}/api/v2/taxes/period/`,
      pendingLicenses: `${server}/api/v2/taxes/period/`,
      stats: `${server}/api/v2/taxes/stats/`,
    },
    retention: {
      activities: `${server}/api/v2/taxes/retention/activities/`,
      add: `${server}/api/v2/taxes/retention/add/`,
      add_statement: `${server}/api/v2/taxes/retention/statement/new/`,
      update_statement: `${server}/api/v2/taxes/retention/statement/update/`,
      reset_statement: `${server}/api/v2/taxes/retention/statement/reset/`,
      change_state: `${server}/api/v2/taxes/retention/statement/changeState/`,
      delete: `${server}/api/v2/taxes/retention/delete/`,
      loadCsv: `${server}/api/v2/taxes/retention/statement/loadCsv/`,
      statement_detail: `${server}/api/v2/taxes/retention/statement/detail/`,
      statement_list: `${server}/api/v2/taxes/retention/statement/`,
      deleteFee: `${server}/api/v2/taxes/retention/statement/deleteFee/`,
      update: `${server}/api/v2/taxes/retention/update/`,
    },
    informal: {
      activities: `${server}/api/v2/taxes/informal/activities/`,
      add_permission: `${server}/api/v2/taxes/informal/addPermission/`,
      get_permissions: `${server}/api/v2/taxes/informal/getPermissions/`,
      permission_detail: `${server}/api/v2/taxes/informal/getPermission/`,
      delete_permission: `${server}/api/v2/taxes/informal/deletePermission/`,
      update_permission: `${server}/api/v2/taxes/informal/updatePermission/`,
      add_activity: `${server}/api/v2/taxes/informal/addActivity/`,
      update_activity: `${server}/api/v2/taxes/informal/updateActivity/`,
      delete_activity: `${server}/api/v2/taxes/informal/deleteActivity/`,
      getStatement: `${server}/api/v2/taxes/informal/getStatement/`,
    },
  },
};

const useTaxes = () => {
  const { currentCustomer } = useSelector((state) => state.customers);
  const { currentLicense } = useSelector((state) => state.taxes);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { adminMode } = useSelector((state) => state.settings);
  const { api } = useAsyncRequest();
  const { t } = useTranslation();

  const getLicenseDetail = async (id) => {
    try {
      const response = await api(
        urls.taxes.license.detail,
        {
          id: id,
          customer_id: currentCustomer.id,
          mode: adminMode ? "admin" : "user",
          action: "retrieve",
        },
        {},
        null
      );
      if (response.data.res === 1) {
        var dependOfLicenseStates = {};
        if (currentLicense && currentLicense.id !== id) {
          dependOfLicenseStates = {
            declarations: [],
            pendingPeriods: [],
            pendingDefinitives: [],
            currentLicenseProcedures: [],
            licenseNotes: [],
          };
        }
        dispatch(
          setTaxesElement({
            currentLicense: response.data.data,
            ...dependOfLicenseStates,
          })
        );
        loadLicenseDeclarations(id, 1, 10);
        setTimeout(() => {
          loadPendingPeriods(id);
        }, 500);
        setTimeout(() => {
          getLicenseNotes(id, 1, 10);
        }, 1000);
      }
    } catch (error) {
      console.log(error);

      return null;
    }
  };

  const loadLicenseDeclarations = async (id, page, paginatedBy) => {
    try {
      const response = await api(
        urls.taxes.license.declarations,
        {
          id: id,
          page,
          page_size: paginatedBy,
          customer_id: currentCustomer.id,
          mode: adminMode ? "admin" : "user",
        },
        {},
        null,
        true
      );
      if (response.data.res === 1) {
        dispatch(
          setTaxesElement({
            declarations: response.data.data,
          })
        );
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const loadPendingPeriods = async (id) => {
    try {
      const response = await api(
        urls.taxes.license.pendingPeriods,
        {
          id: id,
          customer_id: currentCustomer.id,
          mode: adminMode ? "admin" : "user",
        },
        {},
        null,
        true
      );
      if (response.data.res === 1) {
        var pendingPeriods = response.data.data.filter(
          (period) => period.type === 1
        );
        var definitivePeriods = response.data.data.filter(
          (period) => period.type === 2
        );
        dispatch(
          setTaxesElement({
            pendingPeriods: pendingPeriods,
            pendingDefinitives: definitivePeriods,
          })
        );
      }
    } catch (error) {
      return null;
    }
  };

  const getCurrentLicenseProcedures = async () => {
    try {
      const response = await api(
        urls.taxes.license.getProcedures,
        {
          id: currentLicense.id,
          customer_id: currentCustomer.id,
          mode: adminMode ? "admin" : "user",
        },
        {},
        t("Procedimientos de licencia"),
        true
      );
      if (response.data.res === 1) {
        dispatch(
          setTaxesElement({
            currentLicenseProcedures: response.data.data,
          })
        );
      }
    } catch (error) {
      return null;
    }
  };

  const getLicenseNotes = async (id, page, paginatedBy) => {
    try {
      const response = await api(
        urls.taxes.license.notes,
        {
          id: id,
          customer_id: currentCustomer.id,
          page,
          page_size: paginatedBy,
          mode: adminMode ? "admin" : "user",
        },
        {},
        null,
        true
      );
      if (response.data.res === 1) {
        dispatch(
          setTaxesElement({
            licenseNotes: response.data.data,
          })
        );
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };
  const getTaxesConfig = async () => {
    try {
      const response = await api(
        urls.taxes.config,
        {
          customer_id: currentCustomer.id,
          mode: adminMode ? "admin" : "user",
        },
        {},
        t("Configuración del módulo de hacienda")
      );
      if (response.data.res === 1) {
        dispatch(
          setTaxesElement({
            currentCustomerClassifier: response.data.data.activities,
            liquorActivities: response.data.data.liquorActivities,
            licenseProcedures: response.data.data.procedures,
            licenseCategories: response.data.data.licenseCategories,
            declarationFileTypes: response.data.data.declarationsFileTypes,
          })
        );
      }
    } catch (error) {
      return null;
    }
  };
  const addLicenseNote = async (data) => {
    try {
      const response = await api(
        urls.taxes.license.addNote,
        {
          ...data,
          customer_id: currentCustomer.id,
          mode: adminMode ? "admin" : "user",
        },
        {},
        t("Agregando nota")
      );
      if (response.data.res === 1) {
        getLicenseNotes(currentLicense.id, 1, 10);
        return response.data.data;
      } else {
        enqueueSnackbar(response.data.message, { variant: "error" });
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  const changeLicenseNote = async (data) => {
    try {
      const response = await api(
        urls.taxes.license.changeNote,
        {
          ...data,
          customer_id: currentCustomer.id,
          mode: adminMode ? "admin" : "user",
        },
        {},
        t("Editando nota")
      );
      if (response.data.res === 1) {
        getLicenseNotes(currentLicense.id, 1, 10);
        return response.data.data;
      } else {
        enqueueSnackbar(response.data.message, { variant: "error" });
        return null;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const attachNoteFile = async (data) => {
    try {
      var formData = new FormData();
      formData.append("file", data.file);
      formData.append("id", data.id);
      formData.append("customer_id", currentCustomer.id);
      formData.append("mode", admin ? "admin" : "user");

      const response = await api(
        urls.taxes.license.addNoteFile,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
        t("Adjuntando archivo")
      );
      if (response.data.res === 1) {
        getLicenseNotes(currentLicense.id, 1, 10);
        return response.data.data;
      } else {
        enqueueSnackbar(response.data.message, { variant: "error" });
        return null;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const downloadNoteFile = async (id) => {
    try {
      const response = await api(
        urls.taxes.license.downloadNoteFile,
        {
          id: id,
          customer_id: currentCustomer.id,
          mode: adminMode ? "admin" : "user",
        },
        {
          responseType: "blob",
        },
        t("Descargando archivo")
      );
      handleDownloadFile(response);
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const createLicense = async (data) => {
    try {
      const response = await api(
        urls.taxes.license.add,
        {
          ...data,
          customer_id: currentCustomer.id,
          mode: adminMode ? "admin" : "user",
        },
        {},
        t("Creando licencia")
      );
      if (response.data.res === 1) {
        return response.data.data;
      } else {
        enqueueSnackbar(response.data.message, { variant: "error" });
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  const createFromImage = async (data) => {
    try {
      const response = await api(
        urls.taxes.license.createFromImage,
        {
          ...data,
          customer_id: currentCustomer.id,
          mode: adminMode ? "admin" : "user",
        },
        {},
        t("Activando licencia")
      );
      if (response.data.res === 1) {
        return response.data.data;
      } else {
        enqueueSnackbar(response.data.message, { variant: "error" });
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  const addRetentionStatement = async (data) => {
    try {
      const response = await api(
        urls.taxes.retention.add,
        {
          ...data,
          customer_id: currentCustomer.id,
        },
        {},
        t("Creando declaración de retención")
      );
      if (response.data.res === 1) {
        return response.data.data;
      } else {
        enqueueSnackbar(response.data.message, { variant: "error" });
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  const attachRetentionStatement = async (data) => {
    try {
      const response = await api(
        urls.taxes.retention.attach,
        {
          ...data,
          customer_id: currentCustomer.id,
        },
        {},
        t("Adjuntando archivo a declaración de retención")
      );
      if (response.data.res === 1) {
        return response.data.data;
      } else {
        enqueueSnackbar(response.data.message, { variant: "error" });
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  const changeRetentionStatement = async (data) => {
    try {
      const response = await api(
        urls.taxes.retention.change,
        {
          ...data,
          customer_id: currentCustomer.id,
        },
        {},
        t("Editando declaración de retención")
      );
      if (response.data.res === 1) {
        return response.data.data;
      } else {
        enqueueSnackbar(response.data.message, { variant: "error" });
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  const addRetention = async (data) => {
    try {
      const response = await api(
        urls.taxes.retention.add,
        {
          ...data,
          customer_id: currentCustomer.id,
        },
        {},
        t("Creando declaración de retención")
      );
      if (response.data.res === 1) {
        return response.data.data;
      } else {
        enqueueSnackbar(response.data.message, { variant: "error" });
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  const updateRetention = async (data) => {
    try {
      const response = await api(
        urls.taxes.retention.update,
        {
          ...data,
          customer_id: currentCustomer.id,
        },
        {},
        t("Actualizando declaración de retención")
      );
      if (response.data.res === 1) {
        return response.data.data;
      } else {
        enqueueSnackbar(response.data.message, { variant: "error" });
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  const deleteRetention = async (data) => {
    try {
      const response = await api(
        urls.taxes.retention.delete,
        {
          ...data,
          customer_id: currentCustomer.id,
        },
        {},
        t("Eliminando declaración de retención")
      );
      if (response.data.res === 1) {
        return response.data.data;
      } else {
        enqueueSnackbar(response.data.message, { variant: "error" });
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  const informalActivities = async () => {
    try {
      const response = await api(
        urls.taxes.informal.activities,
        {
          customer_id: currentCustomer.id,
          mode: adminMode ? "admin" : "user",
        },
        {},
        t("Cargando actividades"),
        true
      );
      if (response.data.res === 1) {
        dispatch(
          setTaxesElement({
            informalActivities: response.data.data.activities,
            informalProcedures: response.data.data.procedures,
          })
        );
        return response.data.data;
      } else {
        enqueueSnackbar(response.data.message, { variant: "error" });
        return null;
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const addInformalPermission = async (data) => {
    try {
      const response = await api(
        urls.taxes.informal.add_permission,
        {
          ...data,
          customer_id: currentCustomer.id,
        },
        {},
        t("Agregando permiso")
      );
      if (response.data.res === 1) {
        dispatch(
          setTaxesElement({ currentInformalPermission: response.data.data })
        );
        return response.data.data;
      } else {
        enqueueSnackbar(response.data.message, { variant: "error" });
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  const getPermissions = async (data) => {
    try {
      var pData = {
        customer_id: currentCustomer.id,
        mode: adminMode ? "admin" : "user",
      };
      if (data) {
        pData = { ...pData, ...data };
      }
      const response = await api(
        urls.taxes.informal.get_permissions,
        pData,
        {},
        t("Cargando permisos"),
        true
      );
      if (response.data.res === 1) {
        dispatch(setTaxesElement({ informalPermissions: response.data.data }));
        return response.data.data;
      } else {
        enqueueSnackbar(response.data.message, { variant: "error" });
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  const getPermissionDetail = async (id) => {
    try {
      const response = await api(
        urls.taxes.informal.permission_detail,
        {
          id: id,
          customer_id: currentCustomer.id,
          mode: adminMode ? "admin" : "user",
        },
        {},
        t("Cargando permiso")
      );
      if (response.data.res === 1) {
        dispatch(
          setTaxesElement({ currentInformalPermission: response.data.data })
        );
        return response.data.data;
      } else {
        enqueueSnackbar(response.data.message, { variant: "error" });
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  const updatePermission = async (data) => {
    try {
      const response = await api(
        urls.taxes.informal.update_permission,
        {
          ...data,
          customer_id: currentCustomer.id,
          mode: adminMode ? "admin" : "user",
        },
        {},
        t("Actualizando permiso")
      );
      if (response.data.res === 1) {
        dispatch(
          setTaxesElement({ currentInformalPermission: response.data.data })
        );
        return response.data.data;
      } else {
        enqueueSnackbar(response.data.message, { variant: "error" });
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  const deletePermission = async (id) => {
    try {
      const response = await api(
        urls.taxes.informal.delete_permission,
        {
          id: id,
          customer_id: currentCustomer.id,
          mode: adminMode ? "admin" : "user",
        },
        {},
        t("Eliminando permiso")
      );
      if (response.data.res === 1) {
        return response.data;
      } else {
        enqueueSnackbar(response.data.message, { variant: "error" });
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  const addPermissionActivity = async (data) => {
    try {
      const response = await api(
        urls.taxes.informal.add_activity,
        {
          ...data,
          customer_id: currentCustomer.id,
          mode: adminMode ? "admin" : "user",
        },
        {},
        t("Agregando actividad")
      );
      if (response.data.res === 1) {
        return response.data.data;
      } else {
        enqueueSnackbar(response.data.message, { variant: "error" });
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  const updatePermissionActivity = async (data) => {
    try {
      const response = await api(
        urls.taxes.informal.update_activity,
        {
          ...data,
          customer_id: currentCustomer.id,
          mode: adminMode ? "admin" : "user",
        },
        {},
        t("Actualizando actividad")
      );
      if (response.data.res === 1) {
        return response.data.data;
      } else {
        enqueueSnackbar(response.data.message, { variant: "error" });
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  const getStatement = async (id) => {
    try {
      const response = await api(
        urls.taxes.informal.getStatement,
        {
          id: id,
          customer_id: currentCustomer.id,
          mode: adminMode ? "admin" : "user",
        },
        {},
        t("Cargando declaración")
      );
      if (response.data.res === 1) {
        return response.data.data;
      } else {
        enqueueSnackbar(response.data.message, { variant: "error" });
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  const resetStatement = async (id) => {
    try {
      const response = await api(
        urls.taxes.retention.reset_statement,
        {
          id: id,
          customer_id: currentCustomer.id,
        },
        {},
        t("Reiniciando declaración")
      );
      if (response.data.res === 1) {
        dispatch(
          setTaxesElement({
            currentRetentionStatement: response.data.data,
          })
        );
      } else {
        enqueueSnackbar(response.data.message, { variant: "error" });
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  return {
    getLicenseDetail,
    getTaxesConfig,
    getCurrentLicenseProcedures,
    createLicense,
    createFromImage,
    loadLicenseDeclarations,
    loadPendingPeriods,
    getLicenseNotes,
    notes: {
      add: addLicenseNote,
      change: changeLicenseNote,
      attachFile: attachNoteFile,
      downloadFile: downloadNoteFile,
    },
    retentionStatement: {
      add: addRetentionStatement,
      attach: attachRetentionStatement,
      change: changeRetentionStatement,
      reset: resetStatement,
    },
    retention: {
      add: addRetention,
      delete: deleteRetention,
      update: updateRetention,
    },
    informal: {
      activities: informalActivities,
      addPermission: addInformalPermission,
      getPermissions: getPermissions,
      getPermissionDetail: getPermissionDetail,
      updatePermission: updatePermission,
      deletePermission: deletePermission,
      addActivity: addPermissionActivity,
      updateActivity: updatePermissionActivity,
      getStatement: getStatement,
    },
  };
};

export default useTaxes;
